/**
 * Created by brunekninja on 14/09/16.
 */

(function($) {
    var page = 1;
    $('.load-more').click(function (event) {
        page++;
        $.ajax({
            url: ajax_feed_obj.ajaxurl,
            type: 'post',
            data: {
                action: 'ajax_feed',
                query_vars: ajax_feed_obj.query_vars,
                page: page,
                body: ajax_feed_obj.body_class
            },
            success: function( html ) {
                if (!html){
                    $('.load-more').prop('disabled', true).text('No more stories');
                }
                else{
                    $('.feed-container').append(
                        '<div id="grid" class="main feed effect-2">' +
                            html +
                        '</div>'
                    );
                    new AnimOnScroll( document.getElementById( 'grid' ), {
                      minDuration : 0.3,
                      maxDuration : 0.9,
                      viewportFactor : 0.3
                    });
                }
            }
        });
    });

})(jQuery);

//sidebar load more
(function($) {
    var page = 1;
    var posts = $('.loaded-recent-posts article.recent-article').length;
    $('.load-more-aside').click(function (event) {
        page++;
        $.ajax({
            url: ajax_feed_obj.ajaxurl,
            type: 'post',
            data: {
                action: 'ajax_feed_sidebar',
                query_vars: ajax_feed_obj.query_vars,
                page: page,
                posts: posts,
                body: ajax_feed_obj.body_class
            },
            success: function( html ) {
                if (!html){
                    $('.load-more-aside').prop('disabled', true).text('No more stories');
                }
                else{
                    $('.widget_recent_entries').append(
                        '<div class="recent-posts ajaxed-recent-posts">' +
                            html +
                        '</div>'
                    );
                }
            }
        });
    });

})(jQuery);
